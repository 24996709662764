/* Farbpalette */
:root {
  --main-color: #f5f3ed;
  /* Cremeweiß */
  --accent-color: #efd8c2;
  /* Beige */
  --highlight-color: #e7d7c7;
  /* Kaschmir */
  --button-color: #f5c9a1;
  /* Sand */
  --text-color: #444;
}

/* Allgemeines Styling */
body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--main-color);
  color: var(--text-color);
  line-height: 1.6;
}

/* Flexibles Layout mit Flexbox */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Header und Navigation */
/* Hintergrundbild für den Header */
header {
  /* Hintergrundbild einstellen */
  background-image: url('../public/images/OEZMD-Header-Image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #f0ede5;
  width: 100%;
  height: 20.9vh;
  /* Adjust this based on your design requirements */
}

/* Weitere CSS-Regeln hier... */



/* Flexbox für Navigation */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  /* Schriftfarbe für die Navigation */

}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav li {
  margin-right: 1rem;
}

nav a {
  color: var(--text-color);
  /* Schriftfarbe für Navigationslinks */
  text-decoration: none;
}

nav a:hover {
  color: var(--highlight-color);
}

/* Footer */
footer {
  background-color: var(--accent-color);
  color: var(--text-color);
  padding: 1rem 0;
  text-align: center;
}

/* Gemeinsames Styling für alle Seiten */
main {
  background-color: #fff;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: var(--text-color);
  /* Schriftfarbe für den Hauptinhalt */

}

/* Button-Styling */
button {
  background-color: var(--button-color);
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Animation für Hover-Effekt */

}

button:hover {
  background-color: #4C4036;
  /* Dunklere Hintergrundfarbe im Hover-Zustand */
  transform: scale(1.05);
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.gallery-img {
  width: 300px;
  /* Du kannst diese Breite anpassen */
  height: auto;
  margin: 0.5rem;
  /* Einen kleinen Abstand zwischen den Bildern hinzufügen */
}

.gallery img:hover {
  transform: scale(1.05);
}

/* Galerie-Gitter-Stile für WeddingGallery */
.wedding-gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* Automatische Spaltenanpassung */
  grid-gap: 20px;
  /* Abstand zwischen den Bildern */
}

.wedding-gallery-img {
  width: 100%;
  /* Bildbreite auf 100% des Containers */
  height: auto;
  /* Automatische Höhenanpassung */
  border-radius: 8px;
  /* Abrundung der Ecken */
  transition: transform 0.3s ease;
  /* Übergangseffekt beim Schweben über das Bild */
}

.wedding-gallery-img:hover {
  transform: scale(1.05);
  /* Vergrößern des Bildes beim Schweben */
}

/* Image Container */
.image-container {
  position: relative;
  overflow: hidden;
  /* Verhindert, dass der Untertitel aus dem Container herausragt */
  border-radius: 8px;
  /* Abrundung der Ecken für das Bild */
}

/* Abgerundete Ecken für Bilder und Container */
img,
.container {
  border-radius: 8px;
}

/* Skalierbare Bilder für responsive Designs */
img {
  max-width: 100%;
  height: auto;
}

/* CSS für das Portfolio */
.portfolio-title {
  text-align: center;
}

/* Services-Seite */

/* Stil für den Services-Abschnitt */
.service {
  padding: 2rem;
  background-color: #f5f5f5;
  /* Hellgrau */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

/* Stil für den Services-Titel */
.service-title {
  font-size: 1.5rem;
  color: #333;
  /* Dunkelgrau */
  margin-bottom: 1rem;
}

/* Stil für die Services-Beschreibung */
.service-description {
  color: #666;
  /* Mittelgrau */
  line-height: 1.6;
  margin-bottom: 1rem;
}

/* Stil für die Services-Icons */
.service-icon {
  font-size: 2rem;
  margin-right: 1rem;
  color: #999;
  /* Hellgrau */
}

/* Stil für die Services-Liste */
.service-list {
  list-style-type: none;
  padding: 0;
}

/* Stil für die einzelnen Service-Elemente */
.service-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

/* Stil für den Service-Name */
.service-name {
  font-weight: bold;
  margin-right: 0.5rem;
  color: #333;
  /* Dunkelgrau */
}

/* Stil für den Service-Preis */
.service-price {
  font-weight: bold;
  color: #4C4036;
  /* Dunkelbraun */
}

/* Stil für den Service-Preis */
.service-price {
  font-weight: bold;
  color: #4C4036;
  /* Dunkelbraun */
}

/* Stil für den Abschnitt "Warum Uns Wählen" */
.why-choose-us {
  padding: 2rem;
  background-color: #fff;
  /* Weiß */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

/* Stil für den Titel "Warum Uns Wählen" */
.why-choose-us-title {
  font-size: 1.5rem;
  color: #333;
  /* Dunkelgrau */
  margin-bottom: 1rem;
}

/* Stil für die Warum Uns Wählen-Liste */
.why-choose-us-list {
  list-style-type: none;
  padding: 0;
}

/* Stil für die einzelnen Elemente der Warum Uns Wählen-Liste */
.why-choose-us-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

/* Stil für das Warum Uns Wählen-Icon */
.why-choose-us-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: #4C4036;
  /* Dunkelbraun */
}

/* Stil für den Warum Uns Wählen-Text */
.why-choose-us-text {
  color: #666;
  /* Mittelgrau */
  line-height: 1.6;
}

/* Stil für den Warum Uns Wählen-Titel */
.why-choose-us-title {
  font-size: 1.5rem;
  color: #333;
  /* Dunkelgrau */
  margin-bottom: 1rem;
}



/* CSS für AboutUs.js */
.about-container {
  display: flex;
  align-items: flex-start;
  /* Text oben ausrichten */
  margin-bottom: 20px;
  /* Platz unter dem AboutUs-Container */
}

/* Image Container */
.about-image-container {
  max-width: 300px;
  /* Maximale Breite des Bildcontainers */
  margin-right: 20px;
  /* Abstand zwischen Bild und Text */
}

.about-image-container img {
  display: block;
  width: 100%;
  /* Breite des Bildes auf 100% setzen */
  height: auto;
  border-radius: 8px;
  /* Beibehalten des Eckenradius */
}

/* CSS für den Absatztext neben dem Bild */
/* Stil für den Text neben dem Bild */
.about-text {
  display: inline-block;
  width: 100%;
  margin-left: 0;
  /* Ändern Sie den linken Rand auf 0, um ihn für alle Bildschirmgrößen anzupassen */
  flex: 1;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0;
  /* Entfernen Sie den Rahmen */
  box-shadow: none;
  /* Entfernen Sie den Schatten */
}

/* CSS für AboutUs.js */

/* Untertitel für AboutUs.js */
.about-subtitle {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0 0 8px 8px;
  padding: 10px;
  text-align: center;
  width: 100%;
  /* Breite des Untertitels auf 100% setzen */
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease, bottom 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: var(--text-color);
}

/* Einblenden des Untertitels beim Hovern über den Bildcontainer */
.about-image-container:hover .about-subtitle {
  opacity: 1;
}


/* Untertitel für AboutUs.js */
/* Untertitel Container */
.subtitle-container {
  position: relative;
  width: 100%;
  text-align: center;
}

/* CSS für den Untertitel */
.subtitle {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  /* Transparenter Hintergrund */
  border-radius: 0 0 8px 8px;
  padding: 10px;
  text-align: center;
  width: 100%;
  bottom: -30px;
  /* Untertitel unterhalb des Bildes positionieren */
  left: 50%;
  /* Horizontal zentriert */
  transform: translateX(-50%);
  /* Horizontal zentriert */
  opacity: 0;
  transition: opacity 0.3s ease, bottom 0.3s ease;
  /* Übergangseffekte */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Leichter Schatten */
  color: var(--text-color);
  /* Farbe des Untertiteltextes beibehalten */
}

.image-container:hover .subtitle {
  opacity: 1;
  bottom: 0;
}



/* Teammitglieder-Styling */
.about-team {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  margin-top: 1rem;
}

.about-team-member {
  display: flex;
  align-items: center;
  /* Zentriert vertikal */
}


.about-team-member img {
  width: 100%;
  height: auto;
}

.about-team-member p {
  margin-top: 0.5rem;
}

/* Kontaktinformationen und Social-Media-Icons */
.contact-info {
  margin-top: 1rem;
}

.contact-info p {
  margin: 0.5rem 0;
  color: var(--text-color);
  /* Schriftfarbe für Kontaktinformationen */

}

.social-icons {
  display: flex;
  margin-top: 1rem;
}

.social-icons a {
  margin-right: 1rem;
}

.social-icons img {
  width: 30px;
  height: 30px;
}

/* Formular-Styling */
form {
  display: grid;
  grid-gap: 1rem;
  margin-top: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input,
textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: var(--text-color);
  /* Schriftfarbe für Formulareingaben */

}

/* Absatz-Styling */
p {
  margin-bottom: 1rem;
  line-height: 1.5;
}

/* Link-Styling */
a {
  color: var(--highlight-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--accent-color);
}

/* Listen-Styling */
ul {
  list-style-type: disc;
  margin-bottom: 1rem;
  color: var(--text-color);
  /* Schriftfarbe für Listen */

}

ol {
  list-style-type: decimal;
  margin-bottom: 1rem;
  color: var(--text-color);
  /* Schriftfarbe für Listen */

}

li {
  margin-bottom: 0.5rem;
}

h1,
h2,
h3 {
  margin-bottom: 1rem;

}

/* Einbindung benutzerdefinierter Schriftarten */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* Verwendung der benutzerdefinierten Schriftarten */
body {
  font-family: 'Montserrat', sans-serif;
}

/* Animationsübergänge für Elemente */
.transition {
  transition: all 0.3s ease;
}

/* Übergangszeiten für Hover-Effekte */
button,
a {
  transition: color 0.3s ease;
}

/* Konsistente Abstände zwischen Elementen */
.space {
  margin: 1rem;
}

/* Einheitlicher Schatten für Elemente */
.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Stile für die Seitenstruktur */
.section {
  padding: 2rem 0;
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

/* Farbverlauf für bestimmte Elemente */
.gradient-bg {
  background-image: linear-gradient(to right, #F0EDE5, #C5B8A5);
}

/* Transparente Hintergründe für Elemente */
.transparent-bg {
  background-color: rgba(255, 255, 255, 0.8);
}

/* Ausblenden von Elementen */
.hidden {
  display: none;
}

/* Stile für Icon-Elemente */
.icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

/* Benutzerdefinierte Grid-Layouts */
.custom-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.custom-grid-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Seitenübergänge */
.page-transition {
  transition: opacity 0.3s ease;
}

.page-hidden {
  opacity: 0;
}

/* CSS-Filtereffekte
  um Bildern und anderen Elementen interessante visuelle Effekte zu verleihen.
*/
.filter-grayscale {
  filter: grayscale(100%);
}

.filter-blur {
  filter: blur(2px);
}

.filter-brightness {
  filter: brightness(120%);
}

/* Benutzerdefinierter Cursor-Stil */
.custom-cursor {
  cursor: pointer;
}

.custom-cursor:hover {
  cursor: grab;
}

/* Vollbildhintergründe */
.fullscreen-bg {
  background-size: cover;
  background-position: center;
  height: 100vh;
}


/* Scroll-Effekte um bestimmte Elemente oder Abschnitte Ihrer Website dynamischer zu gestalten.  */
.scroll-fade {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.scroll-fade.in-view {
  opacity: 1;
}

/* Benutzerdefinierte Formularstile */
input[type="text"],
textarea {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4px;
}

input[type="text"]:focus,
textarea:focus {
  outline: none;
  border-color: var(--accent-color);
}

/* Gradienten-Übergänge um fließende Übergänge zwischen verschiedenen Farben oder Abschnitten zu erzeugen. */
.gradient-bg {
  background: linear-gradient(to bottom, #FFFFFF, #F0EDE5);
}

/* Tooltip-Stile um zusätzliche Informationen bei Bedarf bereitzustellen. */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Animierter Hintergrund */
.animated-bg {
  background-color: #F0EDE5;
  background-image: linear-gradient(45deg, #F0EDE5 25%, #C5B8A5 25%, #C5B8A5 50%, #F0EDE5 50%, #F0EDE5 75%, #C5B8A5 75%, #C5B8A5 100%);
  background-size: 200% 200%;
  animation: gradient 3s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

/* Fortschrittsbalken */
.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #eee;
  border-radius: 10px;
}

.progress {
  width: 50%;
  height: 100%;
  background-color: #4C4036;
  border-radius: 10px;
}

/* Benutzerdefinierte Dropdown-Menüs */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Schwebender Effekt */
.float {
  transition: transform 0.3s ease;
}

.float:hover {
  transform: translateY(-5px);
}

/* Benutzerdefinierte Bilderrahmen */
.image-frame {
  border: 2px solid var(--accent-color);
  border-radius: 8px;
  overflow: hidden;
}

/* Benutzerdefinierte Checkboxen und Radiobuttons */
.custom-checkbox,
.custom-radio {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.custom-checkbox input,
.custom-radio input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.custom-checkbox .checkmark,
.custom-radio .checkmark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #ccc;
}

/* Weitere Anpassungen für Benutzerinteraktion können hier vorgenommen werden */

/* Stile für das Dropdown-Menü */
.dropdown-menu {
  display: none;
  position: fixed;
  /* Positionieren Sie das Menü fest */
  top: 0;
  /* Platzieren Sie das Menü am oberen Rand des Viewports */
  left: 0;
  width: 100%;
  /* Breite des Dropdown-Menüs auf 100% des Viewports setzen */
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  list-style-type: none;
  z-index: 9999;
  /* Stellen Sie sicher, dass das Menü über allem anderen liegt */
}




.dropdown-menu.open {
  display: block;

}

/* Icon verschieben, wenn das Dropdown-Menü geöffnet ist */
.menu-icon {
  display: block;
  cursor: pointer;
  position: relative;
  float: right;
  margin-right: 10px;
  /* Behalten Sie die relative Position bei */
}

.dropdown-menu li {
  margin-bottom: 5px;
}

.dropdown-menu li a {
  color: #333;
  text-decoration: none;
  display: block;
  padding: 5px;
}

.dropdown-menu li a:hover {
  background-color: #f0f0f0;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  header {
    background-size: contain;
    /* Adjust this as needed */
  }
}

/* Additional styles for mobile header adjustments */
@media (max-width: 768px) {
  header {
    font-size: 0.8em;
  }

  body {
    font-size: 14px;
  }

  nav li {
    margin-bottom: 0.5rem;

  }

  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    list-style-type: none;
    z-index: 9999;
  }

  .dropdown-menu.open {
    display: block;
  }

  .menu-icon {
    float: right;
    margin-right: 10px;
  }

  .about-container {
    flex-direction: column;
    /* Ändern Sie die Richtung auf Spalte für kleine Bildschirme */
  }

  /* Bildcontainer */
  .about-image-container {
    max-width: 100%;
    /* Ändern Sie die maximale Breite auf 100% für kleine Bildschirme */
    margin-right: 0;
    /* Entfernen Sie den rechten Rand für kleine Bildschirme */
  }

  /* Untertitel für kleine Bildschirme */
  .subtitle {
    bottom: 0;
  }

  /* Text für kleine Bildschirme */
  .about-text {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    /* Fügen Sie einen oberen Rand hinzu */
    border-radius: 0;
    /* Entfernen Sie den Rahmen */
    box-shadow: none;
    /* Entfernen Sie den Schatten */
  }
}

/* CSS für das Logo */
.logo {
  text-align: center;
}

.logo img {
  width: 120px;
  /* Adjust the logo size as needed */
}