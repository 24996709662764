.google-analytics-banner {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  /* Änderung der Textbox-Farbe zu Weiß */
  border: 1px solid #ccc;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  /* sicherstellen, dass das Banner über anderen Inhalten liegt */
  border-radius: 4px;
  width: 90%;
  /* Breite des Banners auf 90% des Bildschirms erhöhen */
  max-width: 800px;
  /* Maximale Breite des Banners erhöhen */
  text-align: center;
  /* Zentriert den Text */
}

.google-analytics-banner p {
  margin: 0;
  font-size: 14px;
  /* Beibehaltung der Schriftgröße */
  margin-bottom: 10px;
  /* Abstand zwischen Text und Button hinzufügen */
}

.google-analytics-banner button {
  background-color: #d8c6b7;
  /* Verwenden der angegebenen Button-Farbe */
  color: #fff;
  border: none;
  padding: 10px 20px;
  /* Beibehaltung des Padding für den Button */
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  /* Beibehaltung der Schriftgröße */
  margin-right: 10px;
  /* Rechten Abstand zum anderen Button hinzufügen */
}

.google-analytics-banner button.reject-button {
  background-color: #ccc;
  /* Farbe des abgelehnten Buttons */
  cursor: pointer;
  /* Mauszeiger beim Überfahren des abgelehnten Buttons ändern */
  margin-left: 10px;
  /* Linken Abstand zum anderen Button hinzufügen */
}

.google-analytics-banner button.reject-button.disabled {
  opacity: 0.5;
  /* Reduziert die Deckkraft des deaktivierten Buttons */
  pointer-events: none;
  /* Verhindert das Klicken auf den deaktivierten Button */
  cursor: not-allowed;
  /* Ändert den Mauszeiger, wenn der Button deaktiviert ist */
}




.google-analytics-banner button:hover {
  background-color: #c2a996;
  /* Farbe beim Hover-Effekt */
}

.tracking-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tracking-settings {
  display: flex;
  justify-content: center;
}

.tracking-settings label {
  display: block;
  /* Ändern Sie die Anzeige auf block */
  margin-bottom: 10px;
  /* Fügen Sie zusätzlichen Abstand zwischen den Elementen hinzu */
}

.tracking-settings label span {
  font-size: 14px;
}




.tracking-settings .checkbox-container {
  display: flex;
  align-items: center;
}


/* CSS-Änderungen für mobile Geräte */
@media only screen and (max-width: 600px) {
  .google-analytics-banner {
    width: 95%;
    /* Ändern der Breite auf 95% für kleinere Bildschirme */
    max-width: 95%;
    /* Ändern der maximalen Breite auf 95% */
    padding: 10px;
    /* Weniger Padding für kleinere Bildschirme */
    bottom: 0;
    /* Platzierung des Banners am unteren Rand */
    transform: translateX(-50%) translateY(0);
    /* Zentrierung horizontal, ohne vertikale Translation */
    border-radius: 0;
    /* Entfernen der abgerundeten Ecken */
  }

  .google-analytics-banner p {
    font-size: 12px;
    /* Kleinere Schriftgröße für Mobilgeräte */
    margin-bottom: 5px;
    /* Weniger Abstand zum Button */
  }

  .google-analytics-banner button {
    padding: 8px 16px;
    /* Kleinere Buttons */
    font-size: 12px;
    /* Kleinere Schriftgröße für Buttons */
  }
}