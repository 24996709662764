/* CSS für WeddingGallery */

/* Größe und Styling der Galerie */
.wedding-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(250px, auto);
  gap: 20px;
}

.wedding-gallery-img {
  overflow: hidden;
  border-radius: 8px;
  /* Abgerundete Ecken für den Container */
  position: relative;
  transition: transform 0.3s ease;
}

.wedding-gallery-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.wedding-gallery-img:hover {
  transform: scale(1.05);
}

/* Lightbox-Stile */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* Hintergrundfarbe mit Transparenz */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: transparent;
  /* Hintergrundfarbe des Modals transparent machen */
  position: relative;
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  max-width: 90vw;
  /* Maximalbreite der Lightbox: 90% der Viewportbreite */
  max-height: 90vh;
  /* Maximalhöhe der Lightbox: 90% der Viewporthöhe */
  overflow: auto;
  /* Scrollen ermöglichen, falls das Bild größer als die Lightbox ist */
}

.modal img {
  max-width: 100%;
  max-height: 100%;
}

.modal-close {
  position: absolute;
  bottom: 20px;
  /* Position unterhalb des Bildes */
  left: 50%;
  /* Zentrierung horizontal */
  transform: translateX(-50%);
  /* Verschiebung um die Hälfte der eigenen Breite nach links */
  padding: 10px 20px;
  background-color: #d8c6b7;
  /* Hintergrundfarbe */
  border: none;
  cursor: pointer;
  color: white;
  /* Textfarbe */
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  /* Abrundung der Ecken */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Schatten für den Button */
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  /* Übergangseffekte */
}

.modal-close:hover {
  background-color: #bfb2a7;
  /* Helle Hintergrundfarbe beim Hovern */
  transform: translate(-50%, -5px);
  /* Anheben beim Hovern */
}

.active {
  border: 2px solid blue;
  /* Hier kann die Hervorhebung des aktiven Bildes gestaltet werden */
}