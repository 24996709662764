/* digital-business-card.css */
/* Farbpalette */
:root {
    --main-color: #f5f3ed;
    /* Cremeweiß */
    --accent-color: #f0e9dd;
    /* Beige */
    --text-color: #444;
    /* Textfarbe */
    --facebook-color: #3b5998;
    /* Facebook-Blau */
    --instagram-color: #bc2a8d;
    /* Instagram-Lila */
    --tiktok-color: #ee1d52;
    /* TikTok-Pink */
    --whatsapp-color: #25d366;
    /* WhatsApp-Grün */
    --mail-color: #1da1f2;
    /* Hellblau für Mail */
    --phone-color: #128c7e;
    /* Dunkleres Grün für Telefon */
}

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

main {
    padding: 2rem;
}

/* Überschrift, Text und Kontaktinformationen zentriert */
h1,
.card-text,
.contact-info,
.follow-text {
    text-align: center;
}

.contact-info {
    margin-top: 1rem;
}

.contact-info p {
    margin: 0.5rem 0;
    color: var(--text-color);
    /* Verwendung der definierten Textfarbe */
}

.contact-info a {
    color: #000;
    /* Setze die Farbe der Links auf Schwarz */
}

/* Social-Media-Icons */
.social-icons {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.social-icons a {
    margin: 0 10px;
    color: var(--text-color);
    /* Verwendung der definierten Textfarbe */
    font-size: 24px;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: var(--text-color);
    /* Setze die Farbe zurück auf die definierte Textfarbe */
}

.social-icons img {
    width: 40px;
    height: 40px;
    transition: transform 0.3s ease;
}

.social-icons a:hover img {
    transform: scale(1.2);
}

/* Setze die Farben für die Icons */
.social-icons a:hover[href*="facebook.com"] svg {
    color: var(--facebook-color);
}

.social-icons a:hover[href*="instagram.com"] svg {
    color: var(--instagram-color);
}

.social-icons a:hover[href*="tiktok.com"] svg {
    color: var(--tiktok-color);
}

.social-icons a:hover[href*="wa.me"] svg {
    color: var(--whatsapp-color);
}

.social-icons a:hover[href^="mailto:"] svg {
    color: var(--mail-color);
}

.social-icons a:hover[href^="tel:"] svg {
    color: var(--phone-color);
}

/* Spezifische Stile für die Digital Business Card */
.business-card {
    background-color: var(--main-color);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.business-card .contact-info p {
    color: #666;
}

.business-card .social-icons img {
    width: 40px;
    height: 40px;
}

/* CSS-Regeln für das Logo und den Container */
.logo-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    /* Kreisförmiger Container */
    overflow: hidden;
    /* Verhindert, dass das Logo über den Container hinausragt */
    margin: 0 auto 20px;
    /* Zentriert den Container horizontal und fügt Abstand am unteren Rand hinzu */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Schatten für das Logo hinzufügen */
}

.logo {
    width: 100%;
    /* 100% Breite des Logos innerhalb des Containers */
    height: 100%;
    /* 100% Höhe des Logos innerhalb des Containers */
    object-fit: contain;
    /* Skaliert das Bild, um den Container vollständig zu füllen, ohne es zu strecken oder zu verzerren */
    border-radius: 50%;
    /* Kreisförmiges Aussehen */
    transition: transform 0.3s ease;
    /* Übergangseffekt für Hover */
}

.logo:hover {
    transform: scale(1.1);
    /* Vergrößert das Logo beim Hovern über den Container */
}