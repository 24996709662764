.impressum-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .impressum-heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .impressum-section {
    margin-bottom: 30px;
  }
  